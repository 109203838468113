











































































































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import CarFormPart from '@/components/forms/parts/CarFormPart.vue'
import CarDrivingFormPart from '@/components/forms/parts/CarDrivingFormPart.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import PlaceAndDateFormPart from '@/components/forms/parts/PlaceAndDateFormPart.vue'
import ServicesFormPart from '@/components/forms/parts/ServicesFormPart.vue'
import QuartersFormPart from '@/components/forms/parts/QuartersFormPart.vue'
import CarOrderView from '@/components/orderViews/CarOrderView.vue'

// INTERFACES
import { AuthorRequest, CarDrivingResource, CreateTireServiceRequest, NameValueResource } from '@/store/types'

// STORE
import OrderModule from '@/store/modules/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import FleetOrderModule from '@/store/modules/fleet/order'
import DriverOrderModule from '@/store/modules/driver/order'
import Dropzone from '@/components/_uikit/controls/Dropzone.vue'

@Component({
  components: {
    DefaultLayout,
    ValidationProvider,
    ValidationObserver,
    CharacterCard,
    CarFormPart,
    CarDrivingFormPart,
    ControlsRequestFormPart,
    PlaceAndDateFormPart,
    RequestFormPartWrapper,
    ServicesFormPart,
    QuartersFormPart,
    CarOrderView,
    Dropzone,
  },
})
export default class TireServiceOrderCreate extends Mixins(SystemMixin, NotifyMixin) {
  private rules = {
    count: (value: string) => value.length <= 500,
  }

  private form: CreateTireServiceRequest = {
    letterIds: [],
    servicesIds: [],
    carId: 0,
    author: {} as AuthorRequest,
    carDriving: {} as CarDrivingResource,
    city: '',
    date: '',
    comment: '',
    serviceTitle: 'Шиномонтажные работы',
  }

  private isShowEditCar = false
  private isShowComment = false
  private isShowRequestFormPartWrapper = false

  private get isShowCarDriving () {
    return this.role === 'fleet' || this.role === 'mechanic' ? OrderModule.carSearchResponse.haulForStoAndTireFitting || true : true
  }

  private get chosenServices (): NameValueResource[] {
    let result = []
    if (this.$route.params.services) {
      result = JSON.parse(decodeURI(this.$route.params.services))
    }

    return result.length ? result : [result]
  }

  private set chosenServices (values: NameValueResource[]) {
    this.$router.replace({
      name: 'create-order.tire_service',
      params: {
        services: encodeURI(JSON.stringify(values)),
      },
    })
  }

  private get serviceCityList (): string[] {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      return FleetOrderModule.serviceCitiesDictionary
    } else {
      this.form.city = DriverOrderModule.car.region
      return DriverOrderModule.serviceCitiesDictionary
    }
  }

  private get currentMileage () {
    return OrderModule.carSearchResponse.currentMileage
  }

  private created () {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      FleetOrderModule.getOrderDictionaries({ entity: +this.entity, params: { hasRepair: false } })
      FleetOrderModule.getOrderServiceDictionaries({ entity: +this.entity, params: { hasRepair: false } })
    } else {
      this.form.carId = +this.entity
      DriverOrderModule.getOrderDictionaries(+this.entity)
    }
  }

  private handleSetRegion (payload: any) {
    this.form.city = payload
  }

  private handleCloseCarDriving () {
    this.isShowRequestFormPartWrapper = false
    this.form.carDriving = {
      user: {
        name: '',
        surname: '',
        patronymic: '',
        email: '',
        personalPhone: '',
      },
      date: '',
      address: '',
    }
  }

  private handleCloseComment () {
    this.isShowComment = false
    this.form.comment = ''
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const body: any = {
            ...this.form,
            fileIds: this.form.letterIds,
            servicesIds: this.chosenServices.map(({ value }) => +value),
          }

          body.author.type = this.role === 'fleet' || this.role === 'mechanic' ? 'manager' : 'driver'

          if (this.currentMileage) {
            body.currentMileage = this.currentMileage
          }

          // NOTE: Выбор метода отправки в зависимости от роли
          if (this.role === 'fleet' || this.role === 'mechanic') {
            // NOTE: Флит-менеджер
            FleetOrderModule.sendTairService({ entity: +this.entity, body })
              .then((r) => {
                this.$openModal('OrderSendSuccessModal', 'big', {
                  route: {
                    name: `view-order.${r.type}`,
                    params: { orderId: r.id },
                  },
                })
                this.$router.push({ name: 'orders' })
              })
              .catch(() => {
                this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
              })
          } else {
            // NOTE: Водитель авто
            DriverOrderModule.sendTairService({ entity: +this.entity, body })
              .then((r) => {
                this.$openModal('OrderSendSuccessModal', 'big', {
                  route: {
                    name: `view-order.${r.type}`,
                    params: { orderId: r.id },
                  },
                })
                this.$router.push({ name: 'orders' })
              })
              .catch(() => {
                this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
              })
          }
        }
      })
  }
}
